import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PassparameterService {
	thisValue : object;
    syncParameter;
	constructor() { }
	getParameter(){
  		return this.thisValue;
	}
	setParameter(setValue) {
		_.extend(this.thisValue, setValue);
	}
}
