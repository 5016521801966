import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedInSuccess = false;

  constructor() { }
  // sendToken(token: any){
  //   console.log("token is a", token)
  //   localStorage.setItem("LoggedInUser", token)
  // }
  // getToken() {
  //   return localStorage.getItem("LoggedInUser")
  // }
  // isLoggedIn(){
  //   return this.getToken() !== null;

  // }
}
