import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
	providedIn: 'root'
  })
export class kcTokenGetterService {

	constructor(public http: HttpClient, @Inject(LOCAL_STORAGE) private storage: StorageService) {}

	public getKCToken(){

		if (localStorage.getItem('jwToken')&&!this.storage.get('lb4b-token')) {
			const headers = new HttpHeaders({
				'content-type' : 'applicaton/json',
				'authorization': 'Bearer '+ localStorage.getItem('jwToken')
			  })
			return headers;
		}
		if (this.storage.get('lb4b-token')&&this.storage.get('lb4b-token')!='null') {
			let t = atob(JSON.parse(this.storage.get('lb4b-token'))['access_token'].split('.')[1])
			t = JSON.parse(t)["iss"].split("/")
			let token = JSON.parse(this.storage.get('lb4b-token')).access_token
			const headers = new HttpHeaders({
				'content-type': 'applicaton/json',
				'authorization': 'Bearer ' + token,
				'realmName': t[t.length - 1]
			})
			return headers;
		}
		return {}
	}

}


