import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { APIPostFactoryService } from '../../../services/post-factory.service';
import { APIGetFactoryService } from '../../../services/get-factory.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';
import { GlobalvariableService } from '../../../services/globalvariable.service';
import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthService } from '../../../auth/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {

    public helper = new JwtHelperService();
    valForm: FormGroup;
    public token : any;

    constructor(@Inject(LOCAL_STORAGE) public storage: StorageService, public router: Router, public settings: SettingsService, fb: FormBuilder, private postFactory : APIPostFactoryService, private APIGetFactory: APIGetFactoryService, public global: GlobalvariableService, private authService: AuthService) {
        console.log("this is router", this.router)
        this.valForm = fb.group({
            'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
            'password': [null, Validators.required],
            'appname': [null, Validators.required]
        });

    }
    

    submitForm($ev, value: any) {
        var globalStorage = this.storage;
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        if (this.valForm.valid) {
            console.log('Valid!');
            console.log(value);
            var data = value;
            console.log("this is router in submitForm 11", this.router)
            this.postFactory.customPost('Auth', 'login', data).subscribe(async response=>{
                console.log("this is router in submitForm 22", this.router)
                console.log("here is the response", response)
                var rawToken = response['token'];
                var decodedToken = this.helper.decodeToken(rawToken);
                console.log("decodedToken", decodedToken)
                this.storage.set('decodedTokenObj', decodedToken);
                console.log("here is the token number", decodedToken.merchant);
                // this.authService.sendToken(decodedToken.merchant);
                this.authService.isLoggedInSuccess = true;
                // console.log("getToken", this.authService.getToken())
                await this.APIGetFactory.customGet('LARF_Keycloak', 'getKcTokenMerchant', { merchantId: decodedToken.merchant }).subscribe(data => {
                    console.log("live-sec data from lb4b-api:", data);
                    let kc_tokens = JSON.parse(JSON.stringify(data));
                    globalStorage.set('lb4b-token', JSON.stringify(data));
                    localStorage.removeItem("jwToken");
                    console.log("kc tokens:", kc_tokens);
                    setInterval(async () => {
                        var toBackend = {
                            access_token: JSON.parse(this.storage.get('lb4b-token')).access_token,
                            refresh_token: JSON.parse(this.storage.get('lb4b-token')).refresh_token
                        }
                        await this.APIGetFactory.customGet('LARF_Keycloak', 'refreshKcToken', toBackend).subscribe(async data => {
                            console.log("refresj live-sec data from lb4b-api:", data);
                            console.log("before refresh", JSON.parse(globalStorage.get("lb4b-token")))
                            globalStorage.set('lb4b-token', JSON.stringify(data));
                            console.log("After refresh", JSON.parse(globalStorage.get("lb4b-token")))
                        })
                        // 1sec=1*1000 so 1min=60*1000 similarly 30mins=30*60=1800==>1800*1000 secs
                    }, 1800 * 1000);
                this.APIGetFactory.simpleOneGet('LB4_Merchant', {"id":decodedToken.merchant}).subscribe(response=>{
                    console.log("this is router in submitForm 33", this.router)
                    console.log("APIGetFactory response is here", response)
                    if(response['status'] == "StoreUpdated"){
                        this.router.navigate(['/setup/activation'])
                    }else{
                        console.log("else condition is running", this.router);
                        this.router.navigate(['/dashboard/activation-status'])
                    }
                },function(error){
                    console.log("error in merchant one get", error)
                })
               })
            },function(error){
                console.log("error in auth", error)
                this.authService.isLoggedInSuccess = false;
            });
        }
    }

    ngOnInit() {

    }

}
