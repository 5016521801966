import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { menu } from './menu';
import { routes } from './routes';
import { ApploadComponent } from './appload/appload/appload.component';
import { SmartCollectionComponent } from './smart-collection/smart-collection.component';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes),
        PagesModule
    ],
    declarations: [ApploadComponent, SmartCollectionComponent],
    exports: [
        RouterModule
    ],
    providers: []
})

export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService) {
        //menuService.addMenu(menu);
    }
}
