import { LayoutComponent } from '../layout/layout.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ShRegisterComponent } from './pages/sh-register/sh-register.component';
import { ApploadComponent } from './appload/appload/appload.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { AuthGuard } from '../auth/auth.guard';
import { LiveVueModule } from './live-vue/live-vue.module';

export const routes = [
    { path: 'login', component: LoginComponent},
    {
        path: '',
        component: LayoutComponent,
        canActivate : [AuthGuard],
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full', canActivate : [AuthGuard] },
            // { path: 'page', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
            { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'fixuninstall', loadChildren: () => import('./fixuninstall/fixuninstall.module').then(m => m.FixuninstallModule) },
            { path: 'widgets', loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule) },
            { path: 'elements', loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) },
            { path: 'forms', loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule) },
            { path: 'charts', loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule) },
            { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule) },
            { path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule) },
            { path: 'blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule) },
            { path: 'ecommerce', loadChildren: () => import('./ecommerce/ecommerce.module').then(m => m.EcommerceModule) },
            { path: 'extras', loadChildren: () => import('./extras/extras.module').then(m => m.ExtrasModule) },
            { path: 'setup', loadChildren: () => import('./google-street-view-setup/google-street-view-setup.module').then(m => m.GoogleStreetViewSetupModule) },
            { path: 'partner', loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule) },
            { path: 'plan-upgrade', loadChildren: () => import('./plan-upgrade/plan-upgrade.module').then(m => m.PlanUpgradeModule) },
            { path: 'how-it-works', loadChildren: () => import('./how-it-works/how-it-works.module').then(m => m.HowItWorksModule) },
            { path: 'marketing', loadChildren: () => import('./marketing/marketing.module').then(m => m.MarketingModule) },
            { path: 'virtual-gallery', loadChildren: () => import('./virtual-gallery/virtual-gallery.module').then(m => m.VirtualGalleryModule) },
            { path: 'livevue',loadChildren: () => import('./live-vue/live-vue.module').then(m => m.LiveVueModule)},
            { path: 'livesso',loadChildren: () => import('./live-sso/live-sso.module').then(m => m.LiveSsoModule)},
            { path: 'liveflo',loadChildren: () => import('./live-flo/live-flo.module').then(m => m.LIVEFLOModule)},
            { path: 'on-boarding', loadChildren: () => import('./on-boarding/on-boarding.module').then(m => m.OnBoardingModule) },
            { path: 'live-ord', loadChildren: () => import('./live-ord/live-ord.module').then(m => m.LiveOrdModule) },
        ]

        
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent},
    { path: 'register', component: RegisterComponent},
    { path: 'sh-register', component: ShRegisterComponent},
    { path: 'appload', component: ApploadComponent},
    { path: 'recover', component: RecoverComponent},
    { path: 'lock', component: LockComponent},
    { path: 'maintenance', component: MaintenanceComponent},
    { path: '404', component: Error404Component},
    { path: '500', component: Error500Component},

    // Not found
    { path: '**', redirectTo: 'login' }

];
