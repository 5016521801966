import { Component, OnInit } from '@angular/core';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import { APIGetFactoryService } from '../../../services/get-factory.service';
import { APIPostFactoryService } from '../../../services/post-factory.service';
import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { GlobalvariableService } from '../../../services/globalvariable.service';
import * as jwt_decode from 'jwt-decode';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-appload',
  templateUrl: './appload.component.html',
  styleUrls: ['./appload.component.scss']
})
export class ApploadComponent implements OnInit {


    constructor(@Inject(LOCAL_STORAGE) public storage: StorageService, public route: ActivatedRoute, public router:Router, private APIGetFactory: APIGetFactoryService, private apiPostFactory: APIPostFactoryService, public globalVariable: GlobalvariableService, private authService: AuthService) { }
    
    

	ngOnInit() {
        console.log("query params in appload is", this.route.snapshot.queryParamMap);
        // console.log("url in appload is", this.route['_routerState'].url);
		console.log("in appload ")
        var appName = this.route.snapshot.queryParamMap['params']['appname'];
        var signedPayload = this.route.snapshot.queryParamMap['params']['signed_payload'];
        var decodedTokenObj = {};
        // var url = $location.absUrl();
        /*var finalAppName;
        var url;
        if (appName === undefined || appName === null) {
            var n = url.lastIndexOf("appname=");
            var res = url.substr(n+8);
            //console.log('res -->' , res);
            appName = res;
            url = url.concat(finalAppName);
            console.log("url-->", url);
            appName = finalAppName;

        } else {
            finalAppName = appName;
        }*/
        var data = {
            appname: appName,
            signedPayload: signedPayload,
            decodedToken: decodedTokenObj
        };
        this.processAppload(data);
	}

	processAppload(data){
		var globalStorage = this.storage;
		var globalVariable = this.globalVariable;
		var navRouter = this.router;

        this.apiPostFactory.customPost('LB4_Merchant', 'appload', data).subscribe(async response => {
            console.log('LB4_Merchant appload jwt', response);
            localStorage.setItem('jwt', response['token']);
            var decodedToken = jwt_decode(response['token']);
            globalStorage.set('decodedTokenObj', decodedToken);
            globalStorage.set('jwtgenerated', {
                jwt: response['token']
            });
            console.log("appload kctoken starts here...")
            await this.APIGetFactory.customGet('LARF_Keycloak', 'getKcTokenMerchant', { merchantId: decodedToken.merchant }).subscribe(data => {
                console.log("live-sec data from lb4b-api:", data);
                let kc_tokens = JSON.parse(JSON.stringify(data));
                globalStorage.set('lb4b-token', JSON.stringify(data));
                localStorage.removeItem("jwToken");
                console.log("kc tokens:", kc_tokens);
                setInterval(async () => {
                    var toBackend = {
                        access_token: JSON.parse(this.storage.get('lb4b-token')).access_token,
                        refresh_token: JSON.parse(this.storage.get('lb4b-token')).refresh_token
                    }
                    await this.APIGetFactory.customGet('LARF_Keycloak', 'refreshKcToken', toBackend).subscribe(data => {
                        console.log("refresj live-sec data from lb4b-api:", data);
                        console.log("before refresh", JSON.parse(globalStorage.get("lb4b-token")))
                        globalStorage.set('lb4b-token', JSON.stringify(data));
                        console.log("After refresh", JSON.parse(globalStorage.get("lb4b-token")))
                    })
                    // 1sec=1*1000 so 1min=60*1000 similarly 30mins=30*60=1800==>1800*1000 secs
                }, 1800 * 1000);
               
                if (response['merchantStoreStatus'] === 'StoreUpdated') {
                    console.log('LoginFormController merchant store status is StoreUpdated', response['merchantStoreStatus']);
                    this.authService.isLoggedInSuccess = true;
                    navRouter.navigate(['setup/activation']);
                    //$state.go('app.singleview');
                } else {
                    console.log('LoginFormController merchant store status', response['merchantStoreStatus']);
                    this.authService.isLoggedInSuccess = true;
                    navRouter.navigate(['dashboard/activation-status']);
                }
            })
        }, function (error) {
            console.log("error in appload is", error);
            if (error.error.data.status === 'activate your app first') {
                console.log('LoginFormController merchant store status', error.error.data);
                globalStorage.set('',{
                    userEmail: error.error.data.email
                });
                navRouter.navigate(['emailverify']);
            } else if (error.error.data.status === 'User account created verify for next process') {
            	globalStorage.set('',{
                    userEmail: error.error.data.email
                });
                navRouter.navigate(['emailverify']);
            } else if(error.error.data.status == 'Merchant should complete register'){
                console.log('redirect to app register page');
                globalVariable.unregisteredMerchant = error.error.merchant;
                navRouter.navigate(['register']);
            }
        })
	}

}
