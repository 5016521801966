import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'; 
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authservice: AuthService){}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  boolean {
    if(this.authservice.isLoggedInSuccess){
      console.log("Auth Guard working!!!")
      return true;
    }else{
      this.router.navigate(['/login'])
      return false;
    }
  }
  
}
