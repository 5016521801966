import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-smart-collection',
  templateUrl: './smart-collection.component.html',
  styleUrls: ['./smart-collection.component.scss']
})
export class SmartCollectionComponent implements OnInit {
  
  reqData={
    catName:"",
    vendorName:""
  }
  
  UserChoice1:any;
  UserChoice:any;
  customCatName:string;
  customVendorName:string;
  defaultCatName:string=this.data.name;
  defaultVendorName:string= this.data.name +", "+this.data.country;
  removeCat=this.data.removeCat;
  constructor(
    public dialogRef:MatDialogRef<SmartCollectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data : any) { }

  ngOnInit() {
    this.UserChoice=3;
    this.UserChoice1=3;
    
  }
  Onsave(){
    if(this.UserChoice==1){
      this.reqData.catName=this.customCatName;
    }
    else{
      if(this.UserChoice==0){
        this.reqData.catName= this.defaultCatName;
      }
      
    }
    if (this.UserChoice1==1) {
      this.reqData.vendorName=this.customVendorName;
      
    } else {
      if(this.UserChoice==0){
      this.reqData.vendorName=this.defaultVendorName;
      }
     
    }
    console.log("the value of reqdata is",this.reqData);
    if(this.UserChoice==3||this.UserChoice1==3){
      if(this.UserChoice==3){
        this.dialogRef.close("noname")
      }
      if(this.UserChoice1==3){
        this.dialogRef.close("novendor")
      }
       
    }else{
      this.dialogRef.close(this.reqData);
    }
    
  }
  OnCancel(){
    this.dialogRef.close("closed");
  }
  Onyes(){ 
    this.dialogRef.close("delete")
  }
  Onno(){
    this.dialogRef.close("no");
  }
  
}
