import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import {kcTokenGetterService} from './kcTokenGetter.service'


@Injectable({
  providedIn: 'root'
})
export class APIGetFactoryService {

  apiURL: string = environment.apiEndPoint;

  constructor(public http: HttpClient, @Inject(LOCAL_STORAGE) private storage: StorageService, private kcTokenGetterService: kcTokenGetterService) { }

  public simpleOneGet(tableName: string, data: any) {
    console.log(" simpleOneGet sess", this.storage.get('lb4b-token'))
    console.log(" simpleOneGet tableName", tableName, "controlMethod", "data", data)
    const headers= this.kcTokenGetterService.getKCToken();
    return this.http.get(`${this.apiURL}/${tableName}`, { headers: headers, params: data })
  }
  public customGet(tableName: string, controlMethod: string, data: any) {
    // console.log("sess", this.storage.get('lb4b-token'))
    console.log("tableName", tableName, "controlMethod", controlMethod, "data", data)
    const headers= this.kcTokenGetterService.getKCToken();
    return this.http.get(`${this.apiURL}/${tableName}/${controlMethod}`, { headers: headers, params: data })
  }



}

@Injectable({
  providedIn: 'root'
})
export class PartnerGetFactoryService {
  partnerURL: string = environment.partnerEndPoint;

  constructor(public http: HttpClient, @Inject(LOCAL_STORAGE) private storage: StorageService, private kcTokenGetterService: kcTokenGetterService) { }
  public customGet(tableName: string, controlMethod: string, data: any) {
    // console.log("sess", this.storage.get('lb4b-token'))
    console.log("tableName", tableName, "controlMethod", controlMethod, "data", data)
    const headers= this.kcTokenGetterService.getKCToken();
    return this.http.get(`${this.partnerURL}/${tableName}/${controlMethod}`, { headers: headers, params: data })
  }
}

@Injectable({
  providedIn: 'root'
})
export class ImgMetaGetFactory {
  imgmetaAPI: string = environment.imgmetaEndPoint;

  constructor(public http: HttpClient, private kcTokenGetterService: kcTokenGetterService) { }
  public customGet(tableName: string, controlMethod: string, data: any) {
    const headers= this.kcTokenGetterService.getKCToken();
    return this.http.get(`${this.imgmetaAPI}/${tableName}/${controlMethod}`, { headers: headers, params: data })
  }
}

@Injectable({
  providedIn: 'root'
})
export class BillingGetFactoryService {
  billingAPI : string = environment.billingEndPoint;

  constructor(public http: HttpClient, private kcTokenGetterService: kcTokenGetterService) { }
  public customGet(tableName:string, controlMethod:string, data:any){
    const headers= this.kcTokenGetterService.getKCToken();
    return this.http.get(`${this.billingAPI}/${tableName}/${controlMethod}`, { headers: headers, params: data })
  }

  public simpleOneGet(tableName:string, data:any){
    const headers= this.kcTokenGetterService.getKCToken();
    return this.http.get(`${this.billingAPI}/${tableName}/${data}`, { headers: headers })
  }
}
