import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Inject } from '@angular/core';
import {kcTokenGetterService} from './kcTokenGetter.service'

@Injectable({
  providedIn: 'root'
})
export class APIPostFactoryService {

	apiURL :string = environment.apiEndPoint;

	constructor(private http: HttpClient, @Inject(LOCAL_STORAGE) private storage: StorageService, private kcTokenGetterService: kcTokenGetterService) { }

	public customPost(tableName: string, controlMethod: string, data: any) {
		console.log("tableName is", tableName);
		console.log("controlMethod is", controlMethod);
		console.log("data is", data);
		const headers= this.kcTokenGetterService.getKCToken();
		return this.http.post(`${this.apiURL}/${tableName}/${controlMethod}`, data , { headers: headers })
	}

}


@Injectable({
	providedIn: 'root'
})
export class PartnerPostFactoryService {
	partnerURL : string = environment.partnerEndPoint;

	constructor(public http: HttpClient, @Inject(LOCAL_STORAGE) private storage: StorageService, private kcTokenGetterService: kcTokenGetterService) { }
	public customPost(tableName:string, controlMethod:string, data:any){
	  console.log("Partner Post tableName is", tableName);
	  console.log("Partner Post controlMethod is", controlMethod);
	  console.log("Partner Post data is", data);
	  const headers= this.kcTokenGetterService.getKCToken();
	  // return {status:'ok'};
	  return this.http.post(`${this.partnerURL}/${tableName}/${controlMethod}`, data , { headers: headers })
	}
  }

@Injectable({
	providedIn: 'root'
})
export class ImgMetaPostFactory {
	imgmetaAPI : string = environment.imgmetaEndPoint;

	constructor(public http: HttpClient, private kcTokenGetterService: kcTokenGetterService) { }
	public customPost(tableName:string, controlMethod:string, data:any){
		const headers= this.kcTokenGetterService.getKCToken();
		return this.http.post(`${this.imgmetaAPI}/${tableName}/${controlMethod}`, data , { headers: headers })
	}
}

@Injectable({
	providedIn: 'root'
})
export class BillingPostFactoryService {
	billingAPI : string = environment.billingEndPoint;

	constructor(public http: HttpClient, private kcTokenGetterService: kcTokenGetterService) { }
	public customPost(tableName:string, controlMethod:string, data:any){
		const headers= this.kcTokenGetterService.getKCToken();
		return this.http.post(`${this.billingAPI}/${tableName}/${controlMethod}`, data , { headers: headers })
	}
}
