import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule,APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { JwtModule } from "@auth0/angular-jwt";
import { FormWizardModule } from 'angular-wizard-form';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { AppInitService } from './initservice/appinitservice';
// import { ApploadComponent } from './routes/appload/appload/appload.component';
import {AuthService} from './auth/auth.service';
import {AuthGuard} from './auth/auth.guard';
import { SmartCollectionComponent } from './routes/smart-collection/smart-collection.component';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// import { HomeComponent } from './routes/home/home/home.component';

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function initializeApp1(appInitService: AppInitService) {
    return (): Promise<any> => { 
      return appInitService.Init();
    }
  }
export function tokenGetter() {
    return localStorage.getItem("access_token");
}


@NgModule({
    declarations: [
        AppComponent,
        // ApploadComponent
    ],
    entryComponents:[SmartCollectionComponent],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        FormWizardModule,
        StorageServiceModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        NgMultiSelectDropDownModule.forRoot(),
        JwtModule.forRoot({
            config: {
              tokenGetter: tokenGetter,
              whitelistedDomains: [""],
              blacklistedRoutes: [""]
            }
          })
        
    ],
    providers: [AuthService, AuthGuard,AppInitService, { provide: APP_INITIALIZER,useFactory: initializeApp1, deps: [AppInitService], multi: true}],
    bootstrap: [AppComponent],
})
export class AppModule { }
