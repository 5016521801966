import { Component, Injectable,Input,Output,EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalvariableService {
  decodedTokenObj: Object;
  unregisteredMerchant: [];
  constructor() { }
}
